import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const DataEngineering = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="DataEngineering"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Data engineering is a process to extract, transform, and load the data through various platforms so that the data quality, data standardization, data integrity specific needs and requirements are addressed in data journey. In addition to these, TEQT considers following critical aspects of the data engineering solution:</p>

                        <ul className="circle-list">
                            <li className="mt-2"> <span className="text-black">Scalability – </span> A well solution design in data engineering can accommodate the data volume and its growth so that the data processing would be controlled effectively and efficiently.</li>
                            <li className="mt-2"><span className="text-black">Automation –</span>The entire data engineering solution gets automated without any manual interventions to make the data available for the business to take informed decisions.</li>
                            <li className="mt-2"><span className="text-black">Speed – </span>Irrespective of data volume and variety, the solution would consider the speed of executing the entire process of data engineering quickly and efficiently.</li>

                            <p className="mt-2">The data engineering solutions get maturity over period based on the evolution of tools and technologies. TEQT leverages the services provided by standard tools. On top of that, it brings different solution components in the form of accelerator frameworks to speed up the execution.</p>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query DataEngineeringPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

DataEngineering.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default DataEngineering;
